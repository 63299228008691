import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/newMessage',
    name: 'newMessage',
    component: () => import('../views/newMessage.vue')
  },
  {
    path: '/newsmsg',
    name: 'newsmsg',
    component: () => import('../views/feedback-message.vue')
  },
  {
    path: '/newsdetails',
    name: 'newsdetails',
    component: () => import('../views/news-second.vue')
  },
  {
    path: '/newsSelect',
    name: 'newsSelect',
    component: () => import('../views/newSelect.vue')
  },
  {
    path: '/yearsUpdate',
    name: 'yearsUpdate',
    component: () => import('../views/update-record.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/feedback.vue')
  },
  {
    path: '/allMsg',
    name: 'allMsg',
    component: () => import('../views/all-message.vue')
  },
  {
    path: '/skills',
    name: 'skills',
    component: () => import('../views/skills.vue')
  },
  {
    path: '/killsMessage',
    name: 'killsMessage',
    component: () => import('../views/skills-message.vue')
  },
  {
    path: '/skillsSelect',
    name: 'skillsSelect',
    component: () => import('../views/skills-select.vue')
  },
  {
    path: '/skillsMain',
    name: 'skillsMain',
    component: () => import('../views/skills-main.vue')
  },
]
const router = new VueRouter({
  mode: 'hash',
	base: "/",
	routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior() {
      return {x: 0, y: 0};
  }
})
export default router

